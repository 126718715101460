#body {
  .login {
    padding-top: 2%;
  }

  .card {
    background-color: transparent; // Assuming a solid background for visibility
    color: white; // Dark text for readability
    width: 35%;
    margin-left: auto;
    margin-right: 5%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 2rem;
    box-sizing: border-box;
    min-height: 500px;
  }

  .card-body .form-control {
    position: relative;
    margin-bottom: 1rem; // Adjusted for overall spacing

    .label h2 {
      font-size: 18px; // Increasing font size for labels
      color: white; // Adjusted for visibility
      margin-bottom: 1px; // Reduced gap between label and input
    }

    input[type="text"],
    input[type="password"] {
      font-size: 16px; // Larger font size for readability
    }

    .input {
      border-color: grey;
    }

    .label-text {
      font-size: 16px; // Larger font for readability
    }

    .absolute {
      position: absolute;
      top: 75%;
      right: 10px;
      transform: translateY(-50%);
      cursor: pointer;
    }

    .link {
      font-size: 16px; // Slightly larger font for links
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .checkbox {
    transform: scale(0.8); // Enlarges the checkbox
    margin-right: 0.1rem;
    border-color: grey;
  }

  .checkbox-forgot-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; /* Ensure it spans the full width of its container */
  }
  .checkbox-label-container {
    display: flex;
    align-items: center; /* This ensures vertical center alignment of flex items */
  }

  .secondary-button,
  .btn {
    font-size: 16px; // Increased font size for buttons
    padding: 0.75rem 1.5rem;
    margin-top: 20px; // Increased space before the button
  }

  .button-container {
    display: flex;
    justify-content: space-between; /* This ensures that the child elements are pushed to either end */
    align-items: center;
    width: 100%; /* Ensure the container spans the full width */
    margin-bottom: 1rem;
  }

  .back-button {
    display: flex;
    justify-content: flex-start;
  }

  .signup-button {
    display: flex;
    justify-content: flex-end;
  }

  /* If further alignment is needed, you can adjust here */
  .icon-align,
  .text-align {
    display: flex;
    align-items: center;
  }
  .google-login-button {
    background-color: white; // Google's button is typically white
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem;
    border: 1px solid #ccc; // Slight border
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 8px; // Added border-radius for curved borders

    &:hover {
      background-color: #f8f8f8; // Slight hover effect
    }

    .icon {
      margin-right: 10px; // Space between icon and text
    }
  }

  @media (max-width: 768px) {
    .login {
      padding-top: 30%;
      font-family: Poppins;
    }

    .remember {
      font-size: 10px;
    }
    .card {
      width: 90%; /* Make the card wider to utilize more screen space */
      margin-left: 5%; /* Adjust margins for better centering */
      margin-right: 5%;
      padding: 1rem; /* Reduce padding for more space efficiency */
    }

    .card-body .form-control .label h2,
    .card-body .form-control .label-text,
    .link,
    .secondary-button,
    .btn {
      font-size: 14px; /* Adjust font size for better readability on smaller screens */
    }

    .button-container {
      align-items: flex-start; /* Align items to the start for a cleaner look */
    }

    .back-button {
      justify-content: flex-start; /* Center buttons within their container */
    }
    .signup-button {
      justify-content: flex-end; /* Center buttons within their container */
    }

    .absolute {
      top: 100%; /* Adjust the position of the absolute elements, like password visibility toggle */
      right: 0;
      transform: translateY(-100%);
    }
  }
}
