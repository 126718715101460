.carousel {
  position: relative;
  overflow: hidden;
  margin: auto;
}

.carousel-inner {
  display: flex;
  transition: transform 0.5s ease;
}

.slide {
  flex: 0 0 auto;
  margin-right: 10px; /* Add spacing between images */
}

.slide:last-child {
  margin-right: 0; /* Remove right margin from the last slide */
}

.slide img {
  width: 400px; /* Width of the image */
  height: auto;
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px;
  z-index: 1;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}

.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
