// SubscriptionPage.scss
.subscription-page {
    .title-section {
      text-align: center;
      padding: 20px;
      background-color: transparent;
      border-radius: 10px;
      margin-bottom: 30px;
      color: #e50914; // Netflix red for title text
      font-size: 2rem;
      font-weight: bold;
    }
  
    .cards-container {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      gap: 50px;
      
      .card {
        flex: 1;
        min-width: 280px;
        background: linear-gradient(to right, #141414 0%, #1a1b1e 100%); // Dark gradient closer to Netflix's style
        border-radius: 10px;
        overflow: hidden;
        transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
        // Red glowing effect like Netflix
        box-shadow: 0 4px 8px rgba(229,9,20,0.6), 0 0 15px 2px rgba(229,9,20,0.9);
  
        &:hover {
          transform: translateY(-5px);
          box-shadow: 0 4px 8px rgba(229,9,20,0.7), 0 0 20px 5px rgba(229,9,20,1); // Intensify glow on hover
        }
  
        .card-body {
          padding: 20px;
          text-align: center;
          color: #fff; // White text for high contrast
  
          .card-title {
            font-size: 24px;
            margin-bottom: 10px;
            color: #fff; // White color for titles
          }
  
          .card-text {
            margin-bottom: 15px;
            font-size: 16px; // Clear readability
            color: #ccc; // Light grey text
          }
  
          .price {
            font-weight: bold;
            color: #e50914; // Netflix red for price
            font-size: 18px; // Emphasizing price
          }
  
          .subscribe-button {
            background-color: #e50914; // Netflix red for button
            color: white;
            border: none;
            padding: 12px 24px;
            cursor: pointer;
            border-radius: 5px; // Rounded corners for the button
            transition: background-color 0.3s, transform 0.3s;
  
            &:hover {
              background-color: #b20710; // Darker shade of red on hover
              transform: scale(1.05); // Slightly increase size on hover
            }
          }
        }
      }
    }
  }
  