

@import 'daisyui/dist/full.css';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.sidebar-gradient{
  background: #06011A;
border-left: 1px solid rgba(255, 255, 255, 0.2);
box-shadow: 0px 4px 34px #21325B;
}
@font-face {
  font-family: Poppins;
  src: url("./asset//fonts/Poppins-Regular.ttf");
  font-display: swap;
}
