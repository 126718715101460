/* General Layout Adjustments */

body,
html {
  background-color: #101026; /* Less intense background color */
  color: #e4e4e6; /* Slightly lighter font color for better readability */
}

.orders-list-container h2,
.sidebar-info h3,
.sidebar-info p,
.order-info-title {
  font-family: "Poppins", sans-serif; /* Ensures consistency */
  line-height: 1.6; /* Improves readability */
}

.sidebar {
  width: 95%; /* More practical for smaller screens */
  transition: right 0.5s ease-in-out; /* Smooth transition */
}

.orders-list-container {
  padding: 4rem 1rem;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  h2 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 1rem;
    margin-top: 3%;
    color: #fbfafc; /* Title color */
  }

  .order-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 50px;
    width: 100%;
    max-width: 1200px;
    margin: 0 0 0 10%;
  }
  .chat-image {
    max-height: 100px; // Limit image width
    height: auto; // Maintain aspect ratio
    margin-top: 5px; // Add some spacing
    border-radius: 8px; // Optional: round corners
  }

  /* Order Card Adjustments to match Drawer style */
  
  .order-card {
    background-color: #0a032a;
    border: 1px solid rgb(82, 81, 81);
    border-radius: 10px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 3%;
    overflow: hidden;
    padding: 20px;
    width: 320px;
    height: 380px;
    box-shadow: 0px 10px 0px 0 rgba(23, 20, 195, 0.2),
      0 6px 20px 0 rgba(53, 14, 230, 0.19);
    z-index: 999; /* You can adjust this value based on your layout's needs */
  }

  .order-image {
    width: 100%; /* Sets the width of the image container to fill the card */
    display: flex;
    justify-content: center; /* Centers the image horizontally */
    overflow: hidden; /* Ensures images larger than the container do not overflow */
  }

  .order-image img {
    width: 300px; /* Sets the width of the image */
    height: 200px; /* Sets the height of the image */
    object-fit: cover; /* Ensures the image covers the box, cropping as necessary */
    border-radius: 0px; /* Optional: Rounds the corners of the image */
    overflow: hidden; /* Hides parts of the image that overflow the box */
  }

  .order-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .order-info-details {
    align-self: flex-start; /* Aligns title and date to the left */
    width: 100%;
  }

  .order-info-title,
  .created-at {
    margin: 0; /* Removes margin to tighten up the layout */
    padding: 0;
    width: 100%;
  }
  .order-price {
    font-weight: bold;
    font-size: 25px;
    margin-top: 10px;
    color: #499bfc;
  }
  .order-info-title {
    font-weight: bold;
  }

  .view-details-button {
    width: 100%; /* Ensures the button takes up the full width */
    margin-top: 10px; /* Adds space between the details and the button */
    background-color: #0a032a;
    border: 1px solid rgb(82, 81, 81);
    color: white;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
}

.price-update-container {
  display: flex;
  flex-direction: column;
  align-items: start; /* Align items to the start for a vertical layout */
}

.submit-price-button {
  margin-top: 10px; /* Adjust this value to increase or decrease the space between the input and the button */
}

.status-button {
  display: flex; /* Use flexbox to align children */
  flex-direction: column; /* Stack buttons vertically */
  align-items: center; /* Center align the buttons horizontally */
  width: 100%; /* Ensure the container takes the full width available */
  justify-content: center; /* Center content vertically in case there's extra space */
  margin-bottom: 20px; /* Space between each button */
}

.status-button button {
  width: 80%; /* Set width to 80% of the container */
  margin-top: 20px; /* Space between buttons */
}

.download-images-container {
  margin-top: 20px; /* Adjust this value to increase or decrease the space between the submit button and the download button */
}

.primary-button {
  width: 50%; /* Ensure button takes full width of its container */
}
.price {
  margin-top: 10px;
  width: 50%; /* Ensure button takes full width of its container */
}
.buttons-container {
  display: flex;
  justify-content: space-between; /* This will place one button on each end */
  width: 100%; /* Take full width to spread out buttons */
}

.view-details-button,
.delete-button {
  margin: 1px; /* Space between buttons */
}

.delete-button {
  color: white; /* White text color */
  border-radius: 5px;
  margin-left: 15px;
  margin-top: 10px;
}

/* Sidebar Styling Adjustments */
.sidebar {
  position: fixed;
  margin-left: 7%; /* Creates a 10% gap on the left */
  right: -450px; /* Slide in from the right */
  top: 100px;
  height: 100vh; /* Full height to match the viewport */
  width: 450px;
  background-color: #06011a;
  padding: 20px;
  box-shadow: 0px 4px 34px #21325b;
  overflow-y: auto; /* Ensures scrollbar management */
  z-index: 1000;
  transition: right 0.3s ease-in-out; /* Smooth transition for sliding in and out */
  scrollbar-width: none; /* For Firefox */
}
.sidebar::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.sidebar-info {
  color: #fbfafc; /* Text color for readability */
  margin-bottom: 200px; /* Adds space at the bottom of the sidebar-info section */
}

.sidebar.open {
  right: 0; /* Slides the sidebar into view */
}

.sidebar-content {
  padding-bottom: 50px; /* Adds space at the bottom */
}

.sidebar-info h3,
.sidebar-info p {
  color: #fbfafc; /* Text color for readability */
}

.sidebar-info h3 {
  font-size: 18px; /* Larger font size for headings */
  font-weight: bold;
  color: #fbfafc;
  margin-top: 10px;
  margin-bottom: 5px;
}

.sidebar-info p {
  font-size: 14px; /* Smaller font size for paragraph text */
  color: #dddddd; /* Lighter color for less emphasis */
  margin-bottom: 10px;
}
.images-container {
  display: flex; /* Aligns children (images) in a row */
  justify-content: flex-start; /* Distributes space evenly around images */
  flex-wrap: wrap; /* Allows items to wrap to the next line as needed */
  margin-bottom: 10px; /* Adds space below the container for separation */
}

.uploaded-image {
  width: 180px; /* Sets the width of the image */
  height: 150px; /* Sets the height of the image */
  object-fit: cover; /* Ensures the image covers the box, cropping as necessary */
  margin: 10px; /* Adds some space around images */
  border-radius: 10px; /* Optional: Rounds the corners of the image */
  overflow: hidden; /* Hides parts of the image that overflow the box */
}

.close-btn {
  cursor: pointer;
  background: none;
  border: none;
  position: absolute;
  top: 20px;
  right: 20px;
}
.close-icon::before,
.close-icon::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px; /* Slightly larger for better visibility */
  height: 2px;
  background-color: #fbfafc;
}

.close-icon::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.close-icon::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.payment-container {
  display: flex;
  flex-direction: column; /* Aligns children vertically */
  justify-content: center; /* Centers children vertically */
  align-items: center; /* Centers children horizontally */
  padding: 10px;
  width: 100%;
  background-color: #1b1c2d; /* Main container background color */
  border-radius: 15px; /* Adds rounded corners for aesthetics */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3); /* Soft shadow for depth */
  margin-bottom: 2%;
  color: white; /* Set text color for the whole container */
}

.payment-header {
  width: 100%; /* Ensures the header fills the container */
  display: flex;
  flex-direction: row; /* Aligns children horizontally */
  justify-content: space-between; /* Distributes space between children */
  align-items: center; /* Vertically centers children in the container */
}

.review-container {
  display: flex;
  flex-direction: column; /* Stacks children vertically */
  align-items: flex-start; /* Align items to the start */
  padding: 10px;
  background-color: #1b1c2d; /* Main container background color */
  border-radius: 15px; /* Adds rounded corners for aesthetics */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3); /* Soft shadow for depth */
  margin-bottom: 2%;
  color: white; /* Set text color for the whole container */
}

.review-header {
  width: 100%; /* Ensures the header fills the container */
  display: flex;
  flex-direction: row; /* Aligns children horizontally */
  justify-content: space-between; /* Distributes space between children */
  align-items: center; /* Vertically centers children in the container */
}

.review-status {
  display: flex;
  flex-direction: column; /* Stacks children vertically */
  align-items: center; /* Align items to the start */
  margin-top: 10px;
  color: white;
  margin-bottom: 10px;
}

.status-container {
  display: flex;
  flex-direction: column; /* Stacks children vertically */
  align-items: flex-start; /* Align items to the start */
  padding: 10px;
  background-color: #1b1c2d; /* Main container background color */
  border-radius: 15px; /* Adds rounded corners for aesthetics */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3); /* Soft shadow for depth */
  margin-bottom: 2%;
  color: white; /* Set text color for the whole container */
}

.status-header {
  width: 100%; /* Ensures the header fills the container */
  display: flex;
  flex-direction: row; /* Aligns children horizontally */
  justify-content: space-between; /* Distributes space between children */
  align-items: center; /* Vertically centers children in the container */
}

.chat-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Keeps the input at the bottom */
  height: 400px; /* Set a fixed height or adjust as necessary */
  padding: 10px;
  background-color: #1b1c2d; /* Main container background color */
  border-radius: 15px; /* Adds rounded corners for aesthetics */
  margin-bottom: 2%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3); /* Soft shadow for depth */
}

.messages-list {
  overflow-y: auto; /* Make this part scrollable */
  flex-grow: 1; /* Allows this element to take up remaining space */
  margin-bottom: 10px; /* Provides spacing between messages and the input box */
  padding: 10px 20px 0px 20px; /* Padding inside the messages list for better text alignment */
  background-color: #1b1c2d; /* Background color for the message area */
}
/* General message styling */
.message {
  background-color: #27264c; /* Default message background color */
  color: #fff; /* Text color for messages */
  padding: 8px 12px; /* Padding inside the message bubble */
  margin: 10px 0; /* Spacing between messages */
  word-wrap: break-word; /* Ensures text breaks to avoid horizontal scrolling */
  max-width: 80%; /* Limits the width of the message bubble */
  position: relative; /* Needed for pseudo-elements */
  clear: both; /* Ensures the message does not wrap around other floated elements */
}
.messages-list {
  overflow-y: auto; // Enables vertical scrolling
  height: 400px; // Adjust according to your needs
}

/* User messages styling */
.message-user {
  background-color: #00bfa5; /* Background color for user messages */
  border-radius: 10px 0px 10px 10px; /* Rounded corners except top right */
  float: right; /* Aligns the message to the left */
}

.message-user::after {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0px;
  right: -10px; /* Position tail on the right side of the message */
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0px 0 20px 20px;
  border-color: transparent transparent transparent #00bfa5; /* Color of the tail matches the bubble */
}

/* Admin messages styling */
.message-admin {
  background-color: #34495e; /* Light green background for contrast */
  color: white;
  border-radius: 0px 10px 10px 10px; /* Rounded corners except bottom left */
  float: left; /* Aligns the message to the right */
}

.message-admin::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -10px; /* Position tail on the left side of the message */
  top: 0px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0px 20px 20px 0px;
  border-color: transparent #34495e transparent transparent; /* Color of the tail matches the bubble */
}

/* Styling for user images inside the chat */
.message-user-image {
  background-color: #00bfa5; /* Background color for user image messages */
  border-radius: 10px;
  float: right;
  position: relative;
  border: 1px solid #00bfa5; /* Adding a thin grey border around images */
  overflow: hidden;
  padding: 5px; /* Added padding around the image */
}

/* Styling for admin images inside the chat */
.message-admin-image {
  background-color: #34495e; /* Background color for admin image messages */
  border-radius: 10px;
  float: left;
  position: relative;
  border: 1px solid #34495e; /* Border color adjusted to match background */
  padding: 5px; /* Added padding around the image */
  overflow: hidden;
}

/* Image tag specific styling within chat messages */
.message img {
  width: 100%;
  border-radius: 10px;
  max-height: 200px;
  object-fit: cover;
}

.message-input-container {
  display: flex;
  align-items: center;
  background-color: transparent; /* Maintains a transparent background */
  border-radius: 20px; /* Rounded borders for the container */
  padding: 6px 10px; /* Reduced padding around the elements */
  position: relative;
  border: 2px solid grey; /* Visible border for clarity */
}

.message-input {
  flex-grow: 1;
  padding: 6px 10px; /* Reduced padding to decrease height */
  border: 2px solid transparent; /* Keeps the border hidden */
  background-color: transparent;
  color: #ddd; /* Light text color for visibility */
  outline: none; /* Removes default focus outline */
  font-size: 14px; /* Optionally reduce font size if necessary */
}

.attach-button,
.send-button {
  background: none;
  border: none;
  color: #ddd;
  cursor: pointer;
  padding: 6px; /* Smaller padding to reduce element size */
}

.attach-button {
  margin-right: 10px; /* Maintains spacing between attach icon and input */
}

.send-button {
  margin-left: 10px; /* Spacing between input and send button */
  background-color: transparent;
  border-radius: 50%;
}

.attach-button i,
.send-button i {
  font-size: 14px; /* Reduced icon size to fit smaller buttons */
}

.input[type="file"] {
  display: none; /* Ensures the file input remains hidden */
}

.status-filter-buttons {
  display: flex;
  justify-content: center; /* Align buttons horizontally center */
  gap: 10px; /* Space between buttons */
  margin: 20px 0; /* Space above and below the button container */
}

.status-filter-buttons button {
  padding: 10px 20px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  background-color: #000000; /* Default background color for non-active buttons */
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.status-filter-buttons button.active {
  background-color: #794dfd; /* Purple background for active button */
}

.status-filter-buttons button:hover {
  background-color: #794dfd; /* Lighter purple on hover */
}

.status-filter-buttons button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(106, 27, 154, 0.5); /* Custom focus indicator for accessibility */
}

.payment-element-container {
  width: 380px; /* You might want to adjust this if it's not a typo */
  min-height: 100px; /* Minimum height to ensure container visibility */
  height: auto; /* Adjusts height dynamically based on content */
  background-color: #1b1c2d; /* Dark background for contrast */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  color: white; /* White text for readability */
}

.payment-content-button {
  display: flex;
  justify-content: center; /* Ensure contents of the button are centered */
  align-items: center;
  background-color: #499bfc; /* Button background color */
  color: white; /* Button text color */
  border: none;
  padding: 10px 20px;
  border-radius: 7px;
  cursor: pointer; /* Change mouse cursor on hover over button */
  width: 100%; /* Adjust width to fit inside the StripeElement */
  margin-top: 10px; /* Space above the button */
  font-size: 16px;
  height: 40px;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.payment-form-container button:hover {
  background-color: #2678d9; /* Darker blue on hover */
}

/* Base styles for collapsible containers */
.collapsible-container {
  overflow: hidden;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1), opacity 0.5s linear;
  max-height: 0;
  opacity: 0;
  width: 100%;
}

.collapsible-container.open {
  max-height: 1000px; /* Approximate max content height */
  opacity: 1;
  transition: max-height 0.8s cubic-bezier(0, 1, 0, 1), opacity 0.8s linear;
}

/* Review and Payment headers */
.collapsible-header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  color: white;
  background: none;
  border: none;
  width: 100%;
}

.collapsible-header h3 {
  margin: 0; /* Keep margin zero for clean centering */
  text-align: center; /* Ensures text is centered if there are multiple lines */
}

.collapsible-header button {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .orders-list-container .order-list {
    justify-content: center; /* Centers the cards horizontally */
    margin: 0 auto; /* Removes the margin to the left */
    padding: 0 20px; /* Adds some padding on the sides for spacing */
  }
  .orders-list-container .order-card {
    /* Adjust the height if needed, or add any mobile-specific styles here */
    width: 90%; /* Adjust the width to make it responsive */
    margin: 10px 0; /* Adds vertical margin for spacing between cards */
  }
  .status-filter-buttons button {
    padding: 10px 15px;
    font-size: 13px;
  }

  .sidebar {
    width: 100%; /* Full width sidebar on smaller screens */
    right: -100%; /* Adjust for full width */
    margin-top: -6%;
  }
  .sidebar-info {
    margin-right: 7.5%;
  }
  .close-btn {
    top: 25px;
    right: 50px;
  }
  .images-container {
    display: flex; /* Aligns children (images) in a row */
    justify-content: flex-start; /* Distributes space evenly around images */
    flex-wrap: wrap; /* Allows items to wrap to the next line as needed */
    margin-bottom: 10px; /* Adds space below the container for separation */
  }

  .uploaded-image {
    width: 150px; /* Sets the width of the image */
    height: 150px; /* Sets the height of the image */
    object-fit: cover; /* Ensures the image covers the box, cropping as necessary */
    margin: 5px; /* Adds some space around images */
    border-radius: 10px; /* Optional: Rounds the corners of the image */
    overflow: hidden; /* Hides parts of the image that overflow the box */
  }

  .sidebar.open {
    left: 0;
  }

  .message-input-container {
    width: 100%; /* Use full width on smaller screens */
    padding: 6px; /* Slightly reduce padding */
    margin: 10px 0; /* Ensure some margin from top and bottom elements */
  }

  .message-input {
    margin-right: 6px; /* Adjust margin between elements */
    padding: 6px 8px; /* Adjust padding inside the input */
  }

  .attach-button,
  .send-button {
    padding: 5px; /* Adjust padding for smaller screens */
    font-size: 15px; /* Adjust font size if necessary */
  }

  .payment-element-container {
    padding: 0px;
    width: 100%; /* Adjust width to fit smaller screens */
  }

  .payment-content-button {
    font-size: 14px; /* Smaller font size for smaller screens */
    width: 100%;
  }
}

@media (max-width: 480px) {
  .payment-form-container {
    padding: 5px;
  }

  .StripeElement {
    padding: 6px 8px; /* Even smaller padding for very small screens */
  }

  .payment-form-container button {
    padding: 6px 8px; /* Adjust padding to fit very small screens */
  }
}
