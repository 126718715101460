.thank-you-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; // Takes full height of the viewport
    background-color: #000000; // A dark background for the white text
  
    h1 {
      margin-bottom: 1rem;
      color: #fff; // White text for the title
      font-size: 2rem; // 32px
    }
  
    p {
      color: #fff; // White text for the paragraph
      font-size: 1.25rem; // 20px
    }
  
    .primary-button {
      padding: 0.75rem 1.5rem;
      background-color: #4A90E2; // Example blue color for the button
      color: white;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      font-size: 1rem;
      margin-top: 2rem; // Space above the button
      transition: background-color 0.3s;
      width: 10%;
  
      &:hover {
        background-color: #367ABD; // A slightly darker blue on hover
      }
    }
  }
  