.privacy-policy {
  h1 {
    font-size: 1.5rem; // Equivalent to text-2xl
    font-weight: bold;
    margin-bottom: 1rem; // Equivalent to mb-4
    text-align: center;
    color: black;

  }

  p {
    text-align: justify;
    color: black;

  }

  h2 {
    font-size: 1.125rem; // Equivalent to text-lg
    font-weight: bold;
    margin-bottom: 0.5rem; // Equivalent to mb-2
    color: black;

  }

  ul {
    padding-left: 1rem; // Equivalent to pl-4
    list-style-type: disc;
    color: black;

  }

  li {
    text-align: justify;
    color: black;

  }
}
