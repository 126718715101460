.marque-navbar {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0 20px;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; // Adjust the height as needed
  transition: all 0.3s ease;
  background: #06011a60; // Semi-transparent background
  backdrop-filter: blur(8px);

  &.navbar-active {
    background: #06011a; // Solid color on scroll
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  .navbar-contents {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px; // Max width for content inside navbar

    .nav-logo {
      img {
        height: auto;
        width: 50px; // Logo size
      }
    }

    .menu-icon {
      display: none;
      cursor: pointer;
      svg {
        font-size: 24px; // Icon size
        color: #fff;
      }
    }

    .nav-links {
      list-style: none;
      display: flex;
      align-items: center;
      gap: 20px;

      li {
        a {
          color: #fff;
          text-decoration: none;
          padding: 5px 10px;
          transition: color 0.3s ease;

          &:hover {
            color: #f00; // Hover effect
          }
        }
      }

      &.active {
        display: block;
        position: absolute;
        top: 100px;
        left: 0;
        width: 100%;
        background: rgba(6, 1, 26, 0.95);
        padding: 20px 0;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .marque-navbar {
    padding: 0;
    height: 80px; // Adjusted for mobile view
    justify-content: space-between; // Ensures logo and menu icon are on opposite ends

    .navbar-contents {
      width: 100%; // Ensures content uses full width
      padding: 0 20px; // Adds padding on the sides
      justify-content: space-between; // Aligns logo and menu icon on opposite sides
      
      .menu-icon {
        display: block;
        position: relative;
        z-index: 10001; // Ensures menu icon is above other content
      }

      .nav-links {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        margin-top: -5%;
        height: 100vh;
        background: #06011a;
        flex-direction: column;
        justify-content: flex-start; // Aligns items from the start
        align-items: center;
        gap: 30px;
        padding-top: 0; // Remove the padding-top to eliminate the gap
        z-index: 10000;

        &.active {
          display: flex;
          padding-top: 80px; // Moves the content down to start below the navbar
        }

        li:first-child {
          margin-top: 10%; // Adds margin to the first item, adjust as needed
        }

        li a {
          font-size: 1.2rem; // Enhances readability
        }
      }
    }
  }
}

