/* General Layout Adjustments similar to order.scss */
body,
html {
  background-color: #06011a; /* Similar background color */
  color: #fbfafc; /* General text color for readability */
}

.users-list-container {
  padding: 4rem 1rem;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  h2 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 1rem;
    margin-top: 3%;
    color: #fbfafc; /* Title color */
  }

  .user-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10%;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto; /* Center the users in the middle of the page */
  }

  /* User Card Adjustments */
  .user-card {
    background-color: #0e0e0e;
    border: 1px solid rgb(82, 81, 81);
    border-radius: 10px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 3%;
    overflow: hidden;
    padding: 20px;
    width: 320px;
    height: auto; /* Adjust based on content */
    box-shadow: 0px 10px 0px 0 rgba(142, 140, 140, 0.2),
      0 6px 20px 0 rgba(88, 88, 88, 0.19);
    z-index: 999;
  }

  .user-info {
    width: 100%;
    text-align: center; /* Center align the user info text */

    h3,
    p {
      margin: 5px 0; /* Tighten up layout */
    }
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .users-list-container .user-card {
    width: 100%; /* Make user cards responsive */
    margin-top: 5%;
    margin-left: 2%;
    margin-right: 2%;
  }

  .user-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10%;
    width: 100%;
    margin-top: 10%;    
    max-width: 1200px;
    margin: 0 auto; /* Center the users in the middle of the page */
  }
 
}
