.create-project-container {
    padding: 4rem 1rem;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  
    h2 {
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 1rem;
      margin-top: 3%;
      text-align: left;
      width: 100%;
      max-width: 600px; // This controls the max width of your heading
      margin-left: 10%; // This offsets your heading to the left, consider removing or adjusting it for center alignment
    }
  
    form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: flex-start; // This aligns form items to the start, consider changing to center for alignment
      width: 100%;
      max-width: 600px; // Controls the max width of your form
      margin-left: 10%; // This offsets your form to the left, consider removing or adjusting for center alignment
  
      label {
        display: block;
        margin-bottom: 0.5rem;
      }
  
      .form-section {
        display: flex;
        flex-direction: column;
        width: 100%; // Ensures the container allows child elements to expand fully
        max-width: 700px; // Consistent max-width for both input and textarea
      
        .input,
        .textarea {
          width: 100%; // Full width of the parent container
          padding: 10px; // Consistent padding
          margin-bottom: 1rem; // Consistent margin
          background-color: #1b1c2d;
          border: 1px solid grey;
          color: white;
          // Other styles as above
        }
      }

  
      .btn-primary {
        font-size: 16px;
        padding: 0.75rem 1.5rem;
        background-color: #fd004e;
        color: white;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease;
        width: auto; // Allows the button to size according to its content
        max-width: 540px; // Matches the max-width of input and textarea
        
        &:hover,
        &:focus {
          background-color: darken(#fd004e, 10%);
        }
  
        &:disabled {
          background-color: darken(#fd004e, 20%);
          cursor: not-allowed;
        }
      }
    }
  
    @media (max-width: 768px) {
      padding: 2rem 1rem;
  
      h2, form {
        margin-top: 15%; // Remove the left margin for smaller screens for better center alignment
      }
  
      form {
        align-items: center; // Center align form items on smaller screens
        margin-right: 10%   ;

        .input,
        .textarea,
        .btn-primary {
          width: 100%; // Inputs and button take full width of the form container
          max-width: none; // Remove max-width constraint on smaller screens
        }
      }
    }
  }
  
