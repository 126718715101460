.footer {
  background: transparent;
  padding: 2.5rem 120px;
}

.footer-body {
  width: 100%;
  margin: auto;
}

.text-and-logo-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.text-container {
  max-width: 80%;
}

.foot-logo {
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoimage {
  width: 100%;
  height: auto;
}

.footer-description-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.footer-image {
  width: 200px;
}

.footer-title,
.footer-subtitle,
.footer-link-header,
.footer-link,
.footer-icons-set,
.footer-link:hover {
  font-family: "Poppins", sans-serif;
  color: #ffffff;
}

.footer-title {
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
}

.footer-subtitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
}

.footer-link-header {
  font-weight: bold;
  font-size: 14px;
  line-height: 52px;
  text-transform: uppercase;
}

.footer-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-icons-set {
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
}

.footer-link:hover {
  color: #21f2ff;
}

.footer-link {
  font-size: 16px;
  line-height: 28px;
}

.copyrights {
  font-weight: 300;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.01em;
}

.icons-copyright {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.close-btn {
  position: fixed;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #fa0000;
  z-index: 10000;
}

/* Medium Screen Responsive Adjustments */
@media (max-width: 820px) {
  .footer {
    padding: 2.5rem 20px;
  }

  .footer-body,
  .footer-description-column,
  .footer-links,
  .footer-icons-set {
    flex-direction: column;
  }

  .footer-link-header {
    line-height: 28px;
  }

  .footer-link,
  .footer-link:hover {
    font-size: 14px;
    line-height: 28px;
  }
}

/* Small Screen Responsive Adjustments */
@media (max-width: 480px) {
  .footer-body {
    padding: 0 1rem;
  }

  .foot-logo {
    width: 15%;
  }

  .footer-title {
    font-size: 16px;
    line-height: 33px;
  }

  .footer-subtitle {
    font-size: 14px;
    line-height: 32px;
  }

  .footer-links,
  .icons-copyright,
  .footer-icons-set {
    flex-direction: column;
    align-items: center;
  }

  .footer-links {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }

  .footer-icons-set {
    margin-top: 30px;
  }
}
