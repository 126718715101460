.terms-conditions {
    .text-2xl {
      font-size: 1.5rem; // Equivalent to Tailwind's text-2xl
      font-weight: bold;
      margin-bottom: 1rem; // Equivalent to mb-4
      text-align: center;
      color: black;
    }
  
    .text-lg {
      font-size: 1.125rem; // Equivalent to Tailwind's text-lg
      font-weight: bold;
      margin-bottom: 0.5rem; // Equivalent to mb-2
      color: black;
    }
  
    .pl-4 {
      padding-left: 1rem; // Equivalent to pl-4
      color: black;
    }
  
    .list-disc {
      list-style-type: disc;
      padding-left: 1rem; // Ensure it matches with Tailwind's default list styling
    }
  
    .text-justify {
      text-align: justify;
    }
  
    ul {
      display: grid;
      grid-template-columns: 1fr; // Matches sm:grid-cols-1
      gap: 1rem; // Equivalent to gap-4
  
      @media (min-width: 640px) { // sm breakpoint
        grid-template-columns: repeat(1, minmax(0, 1fr)); // sm:grid-cols-1
      }
    }
  }
  