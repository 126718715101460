.create-section {
  background-image: url("../asset/Frame2.png");
  background-position: top left;
  background-repeat: no-repeat;
}

.create-header {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  color: #ffffff;
}
.points-container {
  display: flex;
  justify-content: space-between; /* Align children to opposite ends */
  position: relative; /* Establish positioning context */
  flex-direction: column;
}
.create-main-text, .create-right-text {
  /* Common styles for positioning */
  position: absolute;
  top: 0; /* Align to the top of the container */
}

.create-main-text {
  left: 0; /* Align this div to the left */
  color: white; /* Example color */
}

.create-right-text {
  right: 0; /* Align this div to the right */
  color: white; /* Example color */
}

.point {
  margin-bottom: 20px; // Space between points
  margin-left: 50%;
}

.point-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  text-align: justify;
}

.point-subtitle {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
  text-align: justify;
}


.project-box {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.project-number-text {
  padding: 5px 12px;
  gap: 10px;
  width: 28px;
  height: 28px;
  background: #21325b;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
}

.project-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}

.project-subtitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.7);
}

.create-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.create-main-text {
  font-family: "Poppins", sans-serif; /* Ensure fallback */
  font-style: normal;
  font-weight: 250; /* Note: Standard font weights are 100, 200, ..., 900. Consider using 200 or 300. */
  font-size: clamp(30px, 5vw, 53.4573px); /* Adjust the min and max values as needed */
  line-height: 1.3; /* Adjust line height for better readability */
  text-transform: capitalize;
  background: linear-gradient(
    220.97deg,
    #06011a 9.04%,
    #5528c0 50%,
    #00d2ff 106.61%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}



@media only screen and (max-width: 480px) {
  .create-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}
