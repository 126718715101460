.faq-container {
  padding: 9rem 120px 5rem;
  width: 70%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 1024px) {
    padding: 5rem 50px; // slightly reduced padding for tablet screens
    width: 80%; // slightly wider to use more screen space
  }

  @media (max-width: 768px) {
    padding: 2rem 20px; // further reduced padding for mobile devices
    width: 100%; // full width on smaller screens
  }
}
  
  .faq-box {
    border-radius: 0.5rem;
    width: 100%; // Equivalent to lg:w-1/2
    text-align: center;
     padding: 5% 7%  ;
  background: linear-gradient(
      105.9deg,
      rgba(55, 125, 255, 0.15) 7.44%,
      rgba(255, 255, 255, 0) 66.31%
    ),
    #0a0228;
 
  }
  
  .faq-title {
    font-size: 1.25rem; // Equivalent to text-xl
    font-weight: bold;
    color: #8C98A4;
    margin-bottom: 32px;
    @media (min-width: 1024px) { // Equivalent to lg: classes
      font-size: 2.25rem; // Equivalent to lg:text-4xl
    }
  }
  
  .faq-item + .faq-item {
    margin-top: 10px;
  }
  
  .collapse {
    // Add your custom collapse styles here
  }
  
  .collapse-title {
    display: flex; // Use Flexbox for layout
    justify-content: center; // Center children horizontally
    align-items: center; // Center children vertically
    text-align: center; // Ensure text is centered if it wraps
    position: relative;
    cursor: pointer; // Indicates interactivity
    background-color: transparent;
    color: white;
    font-size: 1.125rem; // Equivalent to text-lg
  
    // Adjust padding to leave space for the arrow while ensuring text remains centered
    padding: 0 2rem; // Add padding on both sides to keep text centered
  
    &:before {
      content: '';
      position: absolute;
      right: 15px; // Position arrow on the right, adjust as necessary
      top: 50%;
      transform: translateY(-50%) rotate(45deg); // Center arrow vertically and rotate
      border: solid white;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
    }
  
    @media (min-width: 1024px) { // Equivalent to lg:text-2xl
      font-size: 1.5rem;
    }
  }
  
  
  .collapse-content {
    background-color: transparent;
    color: secondary-content; // Define this color according to your theme
    .answer {
      font-weight: semibold;
      font-size: 1.125rem; // Equivalent to text-lg
      color: white;
      @media (min-width: 1024px) {
        font-size: 1.25rem; // Equivalent to lg:text-xl
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .faq-container {
      padding: 10px; /* Reduce the overall padding to use space more efficiently */
      width: auto; /* Let the container adjust its width based on the padding and content */
      margin: 0 10px; /* Ensure there's a little margin on the sides so content doesn't touch the screen edges */
    }
  
    .faq-box {
      padding: 20px; /* Adjust padding to ensure content within the box has some breathing room */
      /* Remove the width: 100%; if it's causing the box to be too thin due to padding */
    }
  
    .faq-title {
      font-size: 1.5rem; /* Continue with your adjusted font size for mobile */
      margin-bottom: 20px; /* Adjusted spacing */
    }
  
    .collapse-title {
      font-size: 1rem; /* Adjusted font size for mobile */
      padding: 0 10px; /* Adjust padding to fit the text better */
    }
  
    .collapse-content .answer {
      font-size: 1rem; /* Adjust font size for readability on smaller screens */
    }
  }
  
  
  
  