.settings-container {
    padding: 4rem 1rem;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  
    h2 {
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 1rem;
      text-align: center; // Center align the heading for settings
      width: 100%;
      max-width: 600px; // Adjust based on your form's width
      margin-top: 4%;
    }
  
    form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: center; // Center align form items for a neat look
      width: 100%;
      max-width: 600px; // Controls the max width of your form
  
      .form-section {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 700px;
  
        label {
          display: block;
          margin-bottom: 0.5rem;
          color: #ddd; // Lighten the label color for better visibility
        }
  
        input[type="text"],
        input[type="email"],
        input[type="password"],
        .textarea {
          width: 100%; // Full width of the parent container
          padding: 10px;
          margin-bottom: 1rem;
          background-color: #1b1c2d;
          border: 1px solid grey;
          color: white;
          border-radius: 0.25rem; // Slightly round the corners for a softer look
  
          &::placeholder {
            color: rgba(255, 255, 255, 0.7);
          }
        }
      }
      .button-group {
        display: flex;
        gap: 10px; // Adjust this value as needed
      }
  
      .btn-primary {
        font-size: 16px;
        padding: 0.75rem 1.5rem;
        background-color: #fd004e; // Keep the brand color for consistency
        color: white;
        border-radius: 7px; // This value controls the curve. Adjust as needed.

        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease;
  
        &:hover,
        &:focus {
          background-color: darken(#fd004e, 10%);
        }
  
        &:disabled {
          background-color: darken(#fd004e, 20%);
          cursor: not-allowed;
        }
      }
    }
  
    @media (max-width: 768px) {
      padding: 2rem 1rem;
  
      h2, form {
        margin-top: 10%; // Adjust margin for smaller screens
        max-width: 90%; // Allow form to take more space
      }
  
      form {
        .form-section {
          .input,
          .textarea,
          .btn-primary {
            width: 100%; // Ensure inputs and button take full width
          }
        }
      }
    }
  }
  