body {
  background-color: #06011a;
  min-height: 100vh; /* This ensures that the body covers at least the whole viewport height */
  margin: 0; /* Resetting default margin */
  padding: 0; /* Resetting default padding */
}
.faq {
  padding: 9rem 120px 5rem;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}


.top-section-hero {
  background-image: url("../asset/Ellipse.png"), url("../asset/back.png");
  background-position: center, center; /* Adjust positions as needed */
  background-repeat: no-repeat, no-repeat;
  background-size: 50%, auto; /* Resize the first image and leave the second image at its original size */
}
/* Container for buttons */
.button-container button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-size: clamp(12px, 2vw, 16px); /* Adjust as necessary */
}

/* Style for buttons (You might not need this if you're satisfied with Material-UI's default styles) */
.button-container button {
  flex: 1 1 auto; /* Adjust buttons to take equal space */
  min-width: 100px; /* Minimum button width */
  margin: 5px; /* Margin around buttons */
}

.hero-body {
  padding: 9rem 120px 5rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.title-container {
  margin-top: 2%;
  position: relative;  // Establishes a positioning context
  display: flex;
  justify-content: space-between; // Keeps the text and image spaced out initially
  align-items: center; // Centers the items vertically

  .title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 50;
    font-size: 53.4573px;
    line-height: 71px;
    text-transform: capitalize;
    background: linear-gradient(
      307.97deg,
      #06011a 9.04%,
      #5528c0 50%,
      #00d2ff 106.61%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  .title-image {
    position: absolute;
    top: 0px;  // Adjust this value to overlap the image over the text
    right: 0;    // Align to the right, adjust as necessary
    z-index: 1; // Puts the image behind the text
    max-width: 50%;  // Restrict size if necessary
    height: auto;  // Maintain aspect ratio
  }
  .title {
    z-index: 999;  // Ensures the text stays on top if overlapping
  }
}

.subtitle {
  margin-top: 2%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.subtitle strong {
  color: rgba(253, 0, 78, 1);
}

.transparent-button {
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

.lets-do-it-box {
  padding: 43px 28px;
  background: linear-gradient(
      105.9deg,
      rgba(55, 125, 255, 0.15) 7.44%,
      rgba(255, 255, 255, 0) 66.31%
    ),
    #0a0228;
  border-radius: 10px;
}

.cards-container {
  display: flex;
  justify-content: center; /* Center the cards within the container */
  flex-wrap: wrap; /* Allow the cards to wrap onto the next line */
  gap: 20px; /* Space between cards */
  padding: 20px; /* Padding around the entire container */
}

/* Circular Text */

.scroll-image {
  -webkit-animation-name: rotate;
  -moz-animation-name: rotate;
  -ms-animation-name: rotate;
  -o-animation-name: rotate;
  animation-name: rotate;
  -webkit-animation-duration: 5s;
  -moz-animation-duration: 5s;
  -ms-animation-duration: 5s;
  -o-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0);
  }
}
@-moz-keyframes rotate {
  from {
    -moz-transform: rotate(360deg);
  }
  to {
    -moz-transform: rotate(0);
  }
}
@-ms-keyframes rotate {
  from {
    -ms-transform: rotate(360deg);
  }
  to {
    -ms-transform: rotate(0);
  }
}
@-o-keyframes rotate {
  from {
    -o-transform: rotate(360deg);
  }
  to {
    -o-transform: rotate(0);
  }
}
@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}

@media only screen and (max-width: 820px) {
  .hero-body {
    padding: 5rem 36px 2rem;
  }
  .title-image {
    max-width: 50%; // Limit the size of the image
    height: auto; // Maintain aspect ratio
  }


  .lets-do-it-box {
    padding: 43px 8px;
    background: linear-gradient(
        105.9deg,
        rgba(55, 125, 255, 0.15) 7.44%,
        rgba(255, 255, 255, 0) 66.31%
      ),
      #0a0228;
    border-radius: 10px;
  }
}

@media only screen and (max-width: 480px) {
  .hero-body {
    padding: 2rem 1rem; /* Reduce padding on smaller screens */
  }
  .jewel-image {
    display: none;
  }
  .button-container {
    flex-direction: row; /* Stack buttons vertically */
  }
  .title {
    margin-top: 20%;
  }
  .subtitle {
    margin-top: 10%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }

  .cards-container {
    padding: 10px; /* Less padding around the container */
    gap: 10px; /* Reduce the gap between cards for tighter layout */
  }

  /* Adjust individual card styles for smaller screens */
  .cards-container .MuiCard-root {
    width: 90%; /* Allow cards to expand to full container width */
    max-width: none; /* Override any max-width set previously */
    margin: 10px 0; /* Adjust margin for vertical spacing, remove horizontal margin */
  }

  /* Ensure text doesn't become too small */
  .cards-container .MuiTypography-h5,
  .cards-container .MuiTypography-h6 {
    font-size: 1rem; /* Adjust font size as needed */
  }
}

/* Optional: Adjust button sizes within cards on smaller screens */
.cards-container .button-container button {
  width: 100%; /* Full width buttons within cards */
  margin: 4px 0; /* Space out buttons vertically */
}
