@import "../sass//variables/variables.scss";

.gallery-section {
  background-image: url("../asset/Frame.png");
  background-position: top right;
  background-repeat: no-repeat;
}

.gallery-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: white;
}

.gallery-subtitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: $color-white;
}
